import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/butcher-baker-nightmare-maker-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 horror movie, Butcher, Baker, Nightmare Maker"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 02</h1>
                    <h2>Butcher, Baker, Nightmare Maker</h2>
                    <h3>March 9, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> William Asher // <b>Starring:</b> Jimmy McNichol &amp; Susan Tyrell</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/02-Butcher--Baker--Nightmare-Maker-e2044ec" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 2, Butcher, Baker, Nightmare Maker"></iframe>
                        </div>
                        <p><Link to="/transcripts/butcher-baker-nightmare-maker">Read the episode transcript</Link></p>
                        <p>This week, Bryan and Dave take a look at the arch riff on Whatever Happened To Baby Jane from 1981, Butcher, Baker, Nightmare Maker, starring Jimmy McNichol and Susan Tyrell. Campy doesn't even begin to cover this one. In what is becoming a disturbing trend at Brother Grim, it's another story about inappropriate family love and the extent that a parent will go to keep their son close to them.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/pieces">Next Episode</Link></li>
                        <li><Link to="/episodes/amityville-2-the-possession">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)